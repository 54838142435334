@import '_theme';
@import '_common';
@import '_mixins';

/* GlobalHeader */
.globalHeader {
  z-index: 2;
  display: grid;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  background: white;
  width: 100%;
  min-width: $largeBreakPoint;
  top: 0px;
  // @include respond_to (large) {}

  &__organization {
    margin-left: 2.5%;
    display: flex;
    width: max-content;
    height: $globalHeaderHeight;
    img {
      width: 100%;
      height: 40px;
      object-fit: contain;
    }

    &__name {
      display: flex;
      align-items: center;
      line-height: $globalHeaderHeight;
      font-weight: 600;
      line-height: $globalHeaderHeight;
      color: $mainFontColor;
      font-size: 22px;
      letter-spacing: -0.5px;
      position: relative;
      max-width: calc(100vw - 10% - 200px);
      span {
        display: inline-block;
        text-transform: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Nunito;
        overflow: hidden;
      }
      .chevronHolder {
        padding-left: 1em;
      }
      @extend %border-bottom-animation;
    }

    &__logoWrapper {
      width: 100px;
      margin-right: 20px;
      padding: 0;
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  &__userFullName {
    color: $mainFontColor;
    font-size: 20px;
    align-self: center;
    font-weight: 500;
    justify-self: right;
    position: relative;
    display: inline-block;
    text-transform: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Nunito;
    max-width: 300px;
    overflow: hidden;
  }

  &__user__nameAvatar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $globalHeaderHeight;
    position: relative;
  }

  &__userSection {
    flex-grow: 1;
    justify-content: flex-end;
    margin-right: 2.5%;
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
  }

  &__userNavbtn {
    user-select: none;
    display: flex;
    align-items: center;
    margin-right: 2.5em;
    height: $globalHeaderHeight;
    line-height: $globalHeaderHeight;
    color: $mainFontColor;
    font-family: Nunito;
    position: relative;
    i {
      color: grey;
    }
    span {
      padding-left: 5px;
    }
    @extend %border-bottom-animation;
  }

  &__userAvatar {
    justify-self: right;
    align-self: center;
    margin-right: 10px;
  }
}

/* GlobalSubHeader */
.globalSubHeader {
  padding-bottom: 15px;
  @extend %wrapper-padding;

  &__title {
    font-size: 40px;
    font-weight: 600px;
    letter-spacing: -1px;
    color: $mainFontColor;
  }

  &__titleHolder {
    font-size: 40px;
    font-weight: 600px;
    display: flex;
    align-items: baseline;
    color: $mainFontColor;
    &__fullname {
      color: $mainFontColor;
      font-size: 40px;
      letter-spacing: -1px;
    }
    &__username {
      color: red;
      font-size: 18px;
      margin-left: 22px;
      letter-spacing: -0.5px;
    }
    &__statusChips {
      margin-left: auto;
      text-transform: uppercase;
      user-select: none;
      font-weight: bold;
      * {
        font-size: 12px;
      }
      div:not(:first-of-type) {
        margin-left: 1em;
      }
      .shell {
        color: $mainFontColor;
        background-color: lightgrey;
      }
      .admin {
        color: white;
        background-color: $mainButtonColor;
      }
    }
  }

  &__path {
    display: flex;
    font-size: 14px;
    margin-top: 5px;
    color: $mainFontColor;
  }

  &__path {
    &__prevPage {
      cursor: pointer;
      letter-spacing: 0.4px;
      &:hover {
        color: red;
      }
    }
    &__currPage {
      display: flex;
      letter-spacing: 0.4px;
      justify-content: flex-start;
      svg {
        transform: translateY(-1px);
      }
    }
    &__lastActive {
      margin-left: auto;
      text-transform: capitalize;
    }
  }
}
